/**
 * Import and instantiate front end modules
 */


import fullpage from 'fullpage.js';
import {updateBodyClassname} from './core/body';
// Initializing it
const fullPageInstance = new fullpage('#main', {
    navigation: true,
    licenseKey: 'T92LK-GR367-O0II8-HT4K6-NRPJN',
    credits: {enabled: false},
    paddingTop: '3rem',
    scrollOverflow: true,
})


updateBodyClassname();


document.addEventListener('DOMContentLoaded', function () {

    const blobs = document.querySelectorAll('.blob');
    blobs.forEach((blob) => {
        // Randomize the animation delay for each blob within a 2-second window
        const randomDelay = Math.random() * 2; // Random delay between 0 and 2 seconds
        blob.style.animationDelay = `${randomDelay}s`;
    });
});





